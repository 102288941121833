<template>
  <v-card>
    <b-modal id="reminder" no-fade="no-fade" title="Reminder" hide-footer static centered no-close-on-backdrop>
      <input type="hidden" v-model="reminderId" />
      <div class="row">
        <div class="col-sm-12">
          <b-button variant="success" class="mb-1 mr-1 change float-right btn-sm" style="font-size: 11px;" @click="setReminderTo(120)">2 hours before</b-button>
          <b-button variant="success" class="mb-1 mr-1 change float-right btn-sm" style="font-size: 11px;" @click="setReminderTo(60)">1 hour before</b-button>
          <b-button variant="success" class="mb-1 mr-1 change float-right btn-sm" style="font-size: 11px;" @click="setReminderTo(30)">30 minutes before</b-button>
          <b-button variant="success" class="mb-1 mr-1 change float-right btn-sm" style="font-size: 11px;" @click="setReminderTo(10)">10 minutes before</b-button>
          <b-form-input v-model="startingDateReminder" type="datetime-local" :max="startingDateReminderMin" :min="computedMaxDate" ></b-form-input>
        </div>
        <div class="col-sm-12 mb-sm-1">
          <textarea v-model="reminderText" class="textarea form-control" rows="2" :placeholder="$t('calendar.add_message')"></textarea>
        </div>
        <div class="col-sm-12 text-right">
          <div class="send-btn">
            <b-button variant="primary" style="font-size:12px;" class="align-text-bottom mb-3 mr-1 btn-sm " @click="saveReminder()"><i class="fas fa-save"></i>Save</b-button>
            <b-button variant="danger" style="font-size:12px;" class="align-text-bottom  mb-3 mr-1 btn-sm" @click="deleteReminder()"><i class="fas fa-ban"></i>Delete</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <v-card-title>
      <b-button @click="addReminder">Add reminder</b-button>
      <v-spacer></v-spacer>
      <v-text-field
        v-model='search'
        append-icon='mdi-magnify'
        label='Search'
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers='headers'
      :items='items'
      :search='search'
    >
      <template v-slot:item.actions='{ item }'>
        <button type='button' class='btn btn-default text-success' @click='addReminder(item)'>
          <i class='ri-pencil-line'></i> {{ $t('employees_list.edit') }}
        </button>
        <button type='button' class='btn btn-default text-danger' @click='deleteReminder(item)'>
          <i class='ri-delete-bin-line'></i> {{ $t('employees_list.delete') }}
        </button>
      </template>
    </v-data-table>
  </v-card>

</template>

<script>
import { core } from '@/config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  data () {
    return {
      search: '',
      headers: [
        { text: 'Date', value: 'theDate' },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'message'
        },
        { text: 'Actions', value: 'actions' }
      ],
      items: [],
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || '',
      startingDateReminder: '',
      startingDateReminderMin: '',
      reminderText: '',
      reminderId: ''
    }
  },
  computed: {
    computedMaxDate () {
      const maxDate = new Date()
      return maxDate.toISOString().slice(0, 16)
    }
  },
  mounted () {
    core.index()
    this.getReminders()
  },
  methods: {
    saveReminder () {
      const postData = {
        theDate: this.startingDateReminder,
        message: this.reminderText,
        id: this.reminderId
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SAVE_REMINDER, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.hasReminder = true
            core.showSnackbar('success', 'Reminder adaugat cu succes!')
            this.$bvModal.hide('reminder')
            this.getReminders()
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    setReminderTo (minutes) {
      const theDate = new Date(this.startingDate)
      theDate.setMinutes(theDate.getMinutes() - minutes)
      this.startingDateReminder = theDate.toISOString().slice(0, 16)
    },
    addReminder (item = null) {
      this.$bvModal.show('reminder')
      if (item) {
        this.startingDateReminder = item.theDate
        this.startingDateReminderMin = item.theDate
        this.reminderText = item.message
        this.reminderId = item.token
      } else {
        this.startingDateReminder = ''
        this.startingDateReminderMin = ''
        this.reminderText = ''
        this.reminderId = ''
      }
    },
    getReminders () {
      const postData = []
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_REMINDERS, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.items = response.data.reminders
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    deleteReminder (item = null) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this reminder?')
        .then(value => {
          if (value === true) {
            const postData = {
              id: item ? item.token : this.reminderId
            }
            Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
            Axios.post(API.API_DELETE_REMINDER, postData)
              .then((response) => {
                if (response.data.status === 'success') {
                  this.startingDateReminder = ''
                  this.startingDateReminderMin = ''
                  this.reminderText = ''
                  this.hasReminder = false
                  core.showSnackbar('success', 'Reminder sters cu succes!')
                  this.$bvModal.hide('reminder')
                  this.getReminders()
                }
              })
              .catch((error) => {
                if (error.response && error.response.status === 401) {
                  this.$router.push({ name: 'auth1.login' })
                }
              })
          }
        })
    }
  }
}
</script>
